/**
 * 
 * Util: Globalisan elerheto egyszeru function: a rendelhetőség kalkulációjához
 * 
 *  params:
 *    - publicationdate: kiadás dátuma
 *    - inventories: egy tömb, ami az adott product raktárkészletét mutatja
 * 
 *  Fontos!
 *    Ezt a fgv-t sokszor meghívjuk, eleinte sok hibás értékkel, amíg be nem tölt a termék
 *    utána már helyes értékeket adja vissza. 
 *    TODO: esetleg csinálhatnánk egy ellenőrzést erre vonatkozóan (ott, ahol meghívtuk)
 * 
*/

// Fgv neve a file neve alapjan fileNeve() (camelCase, without extension)
// export default function (publicationdate: string|null, inventories: Array<object>): string {
export default function (publicationdate: string | null, inventories: any) {

    // DB FIX: korábbi hibás string DB értékek kezelése:
    if (publicationdate === 'notset' || publicationdate === null) {
      publicationdate = '1000-01-01'
    }

    // inventory darabszámok:
    let shopc = 0 // ez tárolja hogy a shopban konkrétan mennyi van
    let gardnersc = 0 // ez tárolja hogy a shopban konkrétan mennyi van

    // végigiterál az inventory-kon, ahol tároljuk a darabszámokat
    for (const inventory of inventories) {
      // a shop (warehouse) id-je:
      if (inventory.warehouseID === '6a7528da-5096-4d0e-b864-08539b66c9fa') {
        shopc = shopc + inventory.inventoryAmount
      } else {
        gardnersc = gardnersc + inventory.inventoryAmount
      }
    }

    // ha van a boltban, akkor minden más eset nem számít:
    if (shopc > 0) {
      //  1: "In Stock"
      return 1
    }

    /* innen az összes "nincs a boltban" kombinációja: */

    // ha nincs a boltban ÉS van a gardners-nél, akkor minden más eset nem számít:
    if (shopc < 1 && gardnersc > 0) {
      // Orderable
      return 2
    }

    // ha nincs a boltban ÉS nincs a gardners-nél ÉS a publication date ismetetlen:
    if (
      (publicationdate === '9999-12-31' ||
        publicationdate === '1000-01-01') &&
      shopc < 1 &&
      gardnersc < 1
    ) {
      // Out of stock text
      return 3
    }

    // ha nincs a boltban ÉS nincs a gardners-nél ÉS a publication date ismert ÉS a publication date későbbi mint a mai nap
    if (
      publicationdate !== '9999-12-31' &&
      publicationdate !== '1000-01-01' &&
      shopc < 1 &&
      gardnersc < 1
    ) {
      const pupdate = new Date(publicationdate);
      if (!isNaN(pupdate.getTime())) {
        const now = new Date();
        if (pupdate > now) {
          // 4: "We accept pre-orders text"
          return 4;
        }
      }
      // const pupdate = moment(publicationdate, 'YYYY-MM-DD')
      // if (pupdate.isValid()) {
      //   const now = moment()
      //   if (pupdate > now) {
      //     //  4: "We accept pre-orders text"
      //     return 4
      //   }
      // }
    }

    // ha nincs a boltban ÉS nincs a gardners-nél ÉS a publication date ismert ÉS a publication date korábbi mint a mai nap
    if (
      publicationdate !== '9999-12-31' &&
      publicationdate !== '1000-01-01' &&
      shopc < 1 &&
      gardnersc < 1
    ) {
      const pupdate = new Date(publicationdate);
      if (!isNaN(pupdate.getTime())) {
        const now = new Date();
      
        if (pupdate <= now) {
          // Out of stock text
          return 3;
        }
      }
      // const pupdate = moment(publicationdate, 'YYYY-MM-DD')
      // if (pupdate.isValid()) {
      //   const now = moment()
      //   if (pupdate <= now) {
      //     // Out of stock text
      //     return 3
      //   }
      // }
    }
  return '';
}
